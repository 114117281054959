.shop{
  margin-bottom: 80px;
  padding: $distance-default * 4 $distance-default;

  @include for-tablet-portrait-up{
    padding: $distance-default * 4 $distance-default * 2;
  }

  @include for-tablet-landscape-up{
    padding: $distance-default * 4 $distance-default * 6;
  }

  .no-shop {
    display: none;
  }

  .ui.table {
    margin-bottom: 75px;
  }

  .offcycle, .oncycle.already-ordered{
    // height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: $distance-above-2 0;

    .cycle-message-content{
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      &:after{
        content:'';
        background: url('../assets/offcycle.png') center center no-repeat;
        background-size: auto 90%;
        width: 100%;
        height: 400px * 0.9;
        float: left;
      }

      @media screen and (min-width: 768px){
        &:before{
          content:'';
          background: url('../assets/offcycle.png') center center no-repeat;
          background-size: auto 90%;
          width: 50%;
          height: 400px * 0.9;
          float: left;
        }

        &:after{
          content: none;
        }
      }

      @include for-tablet-landscape-up{
        &:before{
          content:'';
          background: url('../assets/offcycle.png') right 20px center no-repeat;
          min-height: 404px;
          min-width: 400px;
          width: 50%;
          float: left;
        }

        &:after{
          content: none;
        }
      }

      .textwrapper{
          display: inline-block;
          width: 100%;
          max-width: 408px;
          margin: 0 auto;
          padding: 20px 0px;

          @media screen and (min-width: 768px) {
            width: 50%;
            max-width: inherit;
            margin: inherit;
            padding: 20px 0 0 20px;
          }

          @include for-tablet-landscape-up{
            padding: 80px 0 0 20px;
          }

          p{
            max-width: 480px;
          }

          a.button, button{
            margin: 0px 16px 0px 0px;
          }
      }
    }
  }

  .oncycle.already-ordered{
    .content{
      .textwrapper{
        .button, button{
          margin-bottom: $distance-above-1;
          width: 100%;

          @include for-tablets-up{
            width: auto;
          }
        }
      }
    }
  }

  .shop-table-header{
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $distance-above-2;
    border-bottom: 1px solid $grey-light;

    @media screen and (max-width: 440px) {
      padding-bottom: $distance-above-1;
      margin-top: -$distance-above-1;
    }

    @media screen and (min-width: 441px) and (max-width: 600px) {
      padding-bottom: $distance-above-3;
    }

    $shopintro-image-height: 76px;

    .headline-container{
      flex: inline-block;
      margin-right: 12px;

      h1.header{
        margin-bottom: -$distance-default;
        line-height: 1em;

        @include for-phone-only{
          font-size: 10vw;
        }
      }

      h2.header{
        margin: 0 0;
      }

      h3.header, h4.header{
        margin: 0 0;
        color: $grey;
      }

      h4.header{
        font-size: 1.28571429rem;
        margin: 8px 0 0 -105px;
      }
    }

    .shop-header{
      display: flex;
      flex-direction: row;
      width: 100%;

      @include for-tablet-portrait-up{
          width: 80%;
      }

      &:before{
        content: "";
        float: left;
        height: $shopintro-image-height;
        width: 57px;
        margin-right: 16px;
        background-image: url('../assets/checklist_icon_big_1x.png');
        background-repeat: no-repeat;
      }
    }

    .eyecatcher{
      display: inline-block;
      background-color: $red;
      color: white;
      font-size: .85em;
      text-align: left;
      line-height: 1.5em;
      padding: 14px 20px;
      transform: rotate(5deg) translate(0px,-22px);
      margin-top: 20px;
      margin-left: 4px;

      strong{ line-height: 1.75em; }

      @include for-tablets-up{
        display: inline-block;
        height: 96px;
        width: 96px;
        padding: 28px 0;
        border-radius: 100%;
        text-align: center;
      }
    }

    .shop-header{
      &.offcycle-header{
        &:before{
          content: "";
          background-image: url('../assets/delivering_icon_big_1x.png');
          height: 80px;
          width: 100%;
          max-width: 15%;
          min-width: 52px;
          background-position: center center;
          background-size: 100% auto;
          background-repeat: no-repeat;
          margin-right: 3%;
          margin-top: -12px;

          @include for-tablet-portrait-up{
            max-width: 81px;
            margin-right: 24px;
            margin-top: -$distance-default;
          }
        }

        .headline-container{
          h2, h3{
            display: none;
          }

          h4{
            position: absolute;
            font-size: 3vw;
            left: 8px;
            margin: 10px 0 0 0;

            @include for-tablet-portrait-up{
              position: inherit;
              font-size: 1.28571429rem;
              margin: 8px 0 0 -105px;
            }
          }
        }

        .eyecatcher{
            position: absolute;
            right: -30px;
            margin-top: -6px;
            padding: 8px 40px 8px 16px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            transform: rotate(3deg);

            @media screen and (max-width: 350px) {
              display: none;
            }

            @include for-tablets-up{
              padding: 18px 0;
              margin-top: 0px;
              position: inherit;
              border-radius: 100%;
              right: inherit;
              transform: rotate(5deg);
            }

            &.notice{
              border-radius: 0px !important;
              height: auto !important;
              width: 100% !important;
              padding: 6px 20px !important;
              text-align: left !important;
              max-width: 31%;
              margin: 200px 0 !important;
              right: -3px !important;
              margin: 0 0 0 !important;
              transform: rotate(0deg) !important;
              position: absolute !important;
              top: 70px;

              @include for-tablets-up{
                border-radius: 5px !important;
                height: auto !important;
                width: auto !important;
                padding: 6px 20px !important;
                text-align: left !important;
                max-width: 320px;
                top: 110px;
              }
            }
        }
      }

      &.oncycle-header{
        &:before{
          content: "";
          background-image: url('../assets/delivering_icon_big_1x.png');
          height: 80px;
          width: 100%;
          max-width: 15%;
          min-width: 52px;
          background-position: center center;
          background-size: 100% auto;
          background-repeat: no-repeat;
          margin-right: 3%;
          margin-top: -12px;

          @include for-tablet-portrait-up{
            max-width: 81px;
            margin-right: 24px;
            margin-top: -$distance-default;
          }
        }

        .headline-container{
          h2, h3{
            font-size: 3vw;
            line-height: 4.25vw;

            @include for-tablet-portrait-up{
              font-size: 1.71428571rem;
              line-height: 1.28571429em;
            }
          }
        }

        .eyecatcher{
            position: absolute;
            right: -30px;
            margin-top: 0px;
            padding: 8px 40px 8px 16px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            transform: rotate(3deg);

            @media screen and (max-width: 319px) {
              display: none;
            }

            @include for-tablets-up{
              padding: 30px 0;
              position: inherit;
              border-radius: 100%;
              right: inherit;
              transform: rotate(5deg);
            }
        }
      }
    }

    .food-waste-container {
      height: 96px;
      width: 280px;
      display: none;
      flex-wrap: wrap;
      justify-content: flex-end;

      @include for-tablets-up{
        display: flex;
      }

      * {
        flex: 1 1 100%;
      }

      .food-waste-logo{
        display: inline-block;
        width: 100%;
        margin-top: -22px;
      }

      .food-waste-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 65px;
        margin-top: -32px;
        transform: rotate(-4deg);
      }

      a {
        font-size: 10px;
        font-weight: bold;
      }
    }
  }

  .cart-footer{
    border-top: 1px solid $grey-light;
    padding-top: $distance-above-1;

    .organic {
      height: 72px;
      margin-bottom: 24px;

      @include for-tablets-up{
        margin-bottom: 0;
      }

      .bio__bio-logo{
        display: inline-block;
        width: 80px;
        height: 72px;
        background-image: url('../assets/bio-logo.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: $distance-above-1;

      }

      .bio__eu-logo{
        display: inline-block;
        width: 81px;
        height: 100%;
        background-image: url('../assets/eu-organic-logo.jpg');
        background-repeat: no-repeat;
        background-size: 81px 63px;
        background-position: center center;
      }
    }
  }
}

.ui.form {
  .ui.table {
    border: none;
    margin-bottom: 0px;

    thead {
      tr {
        border-color: $brown-grey;
        border-bottom: 1px solid;
      }
    }

    tr{
      /* restructe article table */
      @include tablet {
        display: grid !important;
        grid-auto-columns: 48%;
        grid-column-gap: 4%;

        &[hidden] {
            display: none !important;
        }

        td{
          &.product-name{
            display: inline-block !important;
            grid-column: 1;
            grid-row: 1;

            .origin{
              display: inline-block;
            }
          }

          &.description{
            grid-column: 1;
            grid-row: 2;
            display: inline-block !important;


            &:before{
              content: "Eigenschaften:";
              display: block;
            }
          }

          &.orderinput{
            grid-column: 1;
            grid-row: 3;
            display: inline-block !important;


            .order-input-group{
              float: left;
              padding-top: 8px;
            }
          }

          &.produce-price{
            grid-column: 2;
            grid-row: 1;
            text-align: right;

          }

          &.quantity{
            grid-column: 2;
            grid-row: 2;
            text-align: right;


            &:before{
              content: "Gebindegrösse:";
              display: block;
            }
          }

          &.spacer{
            grid-column: 1;
            grid-row: 3;
            position: relative;
            left: 160px;
            top: 3px;
            right: 0px;
            text-align: left;
            align-items: center;
            display: flex !important;
          }

          &.article-total{
            grid-column: 2;
            grid-row: 3;
            display: flex !important;
            align-items: center;
            // margin-left: 52px;
            margin-top: 4px;
            flex-direction: row-reverse;
          }

          &.delete-article{
            grid-column: 2;
            grid-row: 3;
            display: flex !important;
            align-items: center;
            flex-direction: row-reverse;
          }
        }

      }
    }

    td, th {
      &:first-child{
        padding-left: 20px;
      }

      &.produce-price {
        padding-left: 1em;
        min-width: 120px;
      }

      &.quantity{
        text-align: center;
      }

      &.spacer {
        width: 90px;
        position: relative;
        background-clip: padding-box;

        &+td{
          padding: .78571429em 20px;
          width: 142px;
        }
      }

      &.amount{
        text-align: center;
        padding: inherit 20px;
      }

      &.sum{
        text-align: center;
      }
    }

    td{
      &.produce-price{
        & div:first-of-type{
          color: $black;
          font-weight: 800;
        }
      }
    }


    @include tablet {
      .no-mobile {
        display: none !important;
      }
    }

    .field {
      margin: 0;
    }

    tr{
      transition: all 0.3s ease;
    }

    tr.has-error {
      border-color: $error;
      background-color: $error-light;
      border-top: 1px solid $error;
      box-shadow: inset 0 -1px 0 0 $error;
      transition: all 0.3s ease;

      .sui-error-message {
        display: none;
      }

      .field.error {
        input,
        .label {
          border-color: $error;
          background: #FFF;
        }

        .order-input {
          height: $input-height;
          border: 2px solid $red;
          border-left: 0px;
          border-right: 0px;
        }
      }

      .ui.button {
        color: $error;
        border-color: $error;
        background: #FFF;
      }

      .custom-error{
        background-color: $red-light;
      }

      .custom-error {
        display: flex;
        align-items: center;
        height: 100%;
        max-width: 136px;;
        justify-content: flex-end;

        @include for-tablet-only{
          font-size: 10px;
          max-width: 124px;
        }

        @include for-tablets-up{
          display: block;
          height: inherit;
        }

      }

      td {
        &.quantity {
          background: $error-light-2;
          color: $error;
          box-shadow: inset 0 -1px 0 0 $error;
        }
      }
    }

    tr.has-changed {
      border-color: $blue-border;
      background-color: $blue-light;
      border-top: 1px solid $blue-border;
      box-shadow: inset 0 -1px 0 0 $blue-border;
      transition: all 0.3s ease;

      .sui-error-message {
        display: none;
      }

      .field.error {
        input,
        .label {
          border-color: $blue-border;
          background: #FFF;
        }

        .order-input {
          height: $input-height;
          border: 2px solid $blue-border;
          border-left: 0px;
          border-right: 0px;
        }
      }

      .custom-error {
        display: block;
      }
    }

    .amount-in-cart, .refresh-cart  {
      // display: flex;
      // align-items: center;
      // height: 100%;
      padding-top: 4px;

      color: $highlight;
      font-size: $font-size-above-one;

      @include for-tablets-up{
        position: inherit;
        right: inherit;
        top: inherit;
        padding-top: inherit;
        display: flex;
        justify-content: flex-end;
      }

      img {
        height: 18px;
        margin-right: 10px;
      }
    }

    tr.isDeleted {
      display: none;
    }

    .custom-error {
      font-size: .9em;
      color: $error;
      line-height: 1.2;

      @include for-tablets-up{
        font-size: $font-size-below-two;
      }
    }

    .product-name div:first-child {
      font-weight: bold;
      color: $black;
    }

    .order-input-group {
      display: flex;
      float: right;

      .field{
        z-index: 50;
      }

      .increment-button {
        // height: 48.5px;
        margin: 0 0;
        border-radius: 0;
        border-color: $green;
        font-weight: 600;
        width: 36px;
        padding: 0 0;
        min-width: inherit;
        z-index: 60;
        font-size: 1.5em;
        line-height: 46px;
        font-weight: 400 !important;
        touch-action: manipulation;

        &:first-child{
          border-top-left-radius: $border-radius-default;
          border-bottom-left-radius: $border-radius-default;
          font-size: 1.8em;

          span{
            display: block;
            transform: translateY(-3px) scaleX(1.15);
          }
        }
        &:last-child{
          border-top-right-radius: $border-radius-default;
          border-bottom-right-radius: $border-radius-default;
        }
      }

      .field{
        input{
          border-radius: 0;
          border-width: 0px;
          border-color: $green;
          text-align: center;
        }
      }
    }

    td.article-total{
      color: black;
      font-weight: 600;
      text-align: center;
    }

    .order-input {
      height: $input-height;
      border: 2px solid $green;
      border-left: 0px;
      border-right: 0px;
      box-sizing: border-box;


      input {
        width: 44px;
        height: 100%;
        padding: 0.4rem 0.4rem;
        color: $grey-dark;
      }

      .label {
        border: 0px solid $green;
        border-radius: 0px;
        border-left: 1px solid $grey;
        border-right: 0px;
        padding: 1.25em 2px 0.75em 2px;
        color: $brown-grey;
        text-align: center;
        font-size: 0.9em;
        background-color: $brown-light;
        width: 28px;
        user-select: none;
      }
    }

    button {
      background-color: $green-light;
      border: 2px solid $highlight-light;
      color: $highlight;
    }
  }

  .addToCart {
    float: right;
  }
}

.shop-table-container {
  text-align: left;

  .filters {
    display: flex;
    // justify-content: space-between;
    padding: $distance-above-1 0 $distance-above-1 0;

    .filter-item {
      text-align: left;
      display: flex;
      flex-direction: column;

      &.search {
        padding-right: 12px;
        position: absolute;
        z-index: 20;
        width: 81vw;

        @include for-tablets-up{
          position: inherit;
        }

        input {
          border: 1px solid $grey;
          border-radius: $border-radius-default;
          box-shadow: inset 0 0 0 0px $green;
          padding: $distance-above-1;
          font-size: $font-size-above-one;
          color: $black;
          transition: all 0.3s ease;

          &:focus {
            border-color: $green;
            box-shadow: inset 0 0 0 2px $green;
            transition: all 0.3s ease;
          }

          &::placeholder {
            font-style: italic;
            color: $grey;
            font-weight: 300;
          }
        }
      }

      &.filter {

        @include for-tablets-up{
          &:first-child{
            padding-right: 12px;
          }
        }

        .dropdown {
          border-color: $highlight;
          border-radius: $border-radius-default;
          padding: $distance-above-1;
          font-size: $font-size-default;
          color: $grey;
          z-index: 70;

          .text {
            color: $black;
            font-weight: 300;
            height: 19px;
          }
        }

        .selection.dropdown {
          padding: $distance-above-1;
          border: 1px solid $grey;
          color: $black;
          min-width: 220px;
        }
      }
    }

    .filter-label {
      color: $black;
      font-weight: 600;
      padding-bottom: $distance-default;
      letter-spacing: 0.2px;
    }

    .filter-wrapper{
      display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      flex-direction: column;
      align-items: flex-end;

      .filter-dropdown{
        height: 58px;
        width: 15vw;
        margin-top: 25px;
        background-color: $green;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: white;
        text-align: center;
        font-weight: 700;
        letter-spacing: .5px;
        padding-top: 6px;

        @include for-tablets-up{
          display: none;
        }

        span{
          &:before{
            content: "";
            height: 16px;
            width: 15px;
            background-image: url('../assets/filter-icon.svg');
            display: block;
            margin: 5px auto;
          }
        }
      }

      .filter-label{
        @media screen and (max-width: 768px) {
          color: white;
        }
      }

      .filter-content{
        display: inline-block;
        background-color: $green;
        width: 100%; //fallback
        width: 96vw;
        padding: 16px 16px;
        margin-top: -4px;
        border-radius: 4px;
        border-top-right-radius: 0px;


        @include for-tablets-up{
          display: flex;
          background-color: inherit;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 0px;
          padding: inherit;
          width: 100%;
        }
      }

      // Export button
      .filter-item + span {
        padding: 25px 0 0 12px;
        display: none;

        button {
          min-width: 185px;
        }

        @include for-tablets-up{
          display: block;
        }
      }
    }
  }
  .results {
    color: $black;
    font-weight: 600;
    font-size: $font-size-below-one;
    padding-bottom: $distance-above-1;

    @include for-phone-only{
      margin-left: 10px;
    }

  }
}
