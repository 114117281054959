.App-container{
  min-height: 100vh;
  display: flex;
  background: url('../assets/bg-image.jpg') repeat left top;

  h1,h2,h3,h4,h5,p,span,label,th,tr,td,div{
    &::selection{
      background: #D7E5D3;
    }
    &::-moz-selection {
      background: #D7E5D3;
    }
  }

  .dark-bg{
  	color: white;
    background-color: #2b2b29;
    background-image: url('../assets/bg-dark.jpg');
    background-image: radial-gradient(transparent, rgba(0,0,0,0.7)),url('../assets/bg-dark.jpg');
    background-position: 0 0;
    background-repeat: repeat;

    h1,h2,h3,h4,h5,p,span,label{
      color: white !important;
    }

    .dropdown .text{
      color: $black !important;
    }
  }

  /* Error and success messages*/
  .ui.message{
    text-align: left;
    padding: 1.75em 2em 1.25em;
    border-radius: $distance-default;

    .header:not(.ui){
      font-size: $font-size-default;
      letter-spacing: 0.25px;
    }

    p{
      margin: $distance-default 0 !important;
    }

    &.positive{
      background-color: $green-light;
      border: 2px solid $green;

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &:before{
        content:"";
        height: 16px;
        width: 16px;
        margin-top:0px;
        display: inline-block;
        margin-right: 12px;
        background-image: url('../assets/success-icon-small.svg');
        background-size: 100% 100%;
      }

      .content{
        display: inline-block;
        width: 94%;
      }

      h1,h2,h3,h4,h5,p,span,label,.header{
        color: $green !important;
      }
    }

    &.negative{
      background-color: $red-light;
      border: 2px solid $red;

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &:before{
        content:"";
        height: 16px;
        width: 16px;
        margin-top:0px;
        display: inline-block;
        margin-right: 12px;
        background-image: url('../assets/error-icon-small.svg');
        background-size: 100% 100%;
      }

      .content{
        display: inline-block;
        width: 94%;
      }

      h1,h2,h3,h4,h5,p,span,label,.header{
        color: $red !important;
      }
    }

    &.neutral{
      background-color: $brown-light;
      border: 2px solid $brown;
      padding: .75em 2em 1.5em;

      display: flex;
      flex-direction: row;
      align-items: flex-start;

      &:before{
        content:"";
        height: 16px;
        width: 16px;
        margin-top:2px;
        margin-bottom: -2px;
        display: inline-block;
        margin-right: 16px;
        background-image: url('../assets/information-icon-brown.svg');
        background-size: 100% 100%;

        @include for-phone-only{
          display: none;
        }
      }

      .content{
        display: inline-block;
        width: 94%;
      }

      h1,h2,h3,h4,h5,p,span,label,.header,div.item{
        color: $brown !important;

        &:before, &:after{
          color: $brown !important;
        }
      }
    }
  }

  .page-loader {
    position: fixed;
  }

  .pusher.dimmed {
    min-height: 100vh;
    position: fixed;

    @include for-tablets-up{
      position: relative;
    }
  }

  .ui.segment.pushable {
    min-height: 100vh;
    width: 100%;
    transform: none;
    border: 0;
    border-radius: inherit;
    background: none;
  }

  .main-menu {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 700;
    height: 70px;

    .item {
      padding: 1em 1.25em;

      @media screen and (min-width: 400px) {
        padding: 1em 1.5em;
      }

      img{
        width: 110px !important;
      }

      &:before{
        display: none;
      }
    }

    .item .logo{
      margin: auto auto !important;
    }

    .menu{
      &.right{
        padding-right: $distance-above-2;

        @include for-phone-only{
          .ui.dropdown .menu > .item{
            font-size: 1.6em !important;
          }
        }

        .item{
          padding: $distance-above-1 $distance-default;
          font-size: $font-size-below-three;
          font-weight: 800;
          display: flex;

          @media screen and (min-width: 400px) {
            padding: $distance-above-1 $distance-above-1;
            font-size: $font-size-below-two;
          }

          div:focus, img:focus{
            outline: none;
            border: none;
          }

          img{
            width: 20px !important;
            height: 20px !important;
            margin: 0 0 6px 0;
          }

          span{
            line-height: 1em;
          }
        }
      }

      .ui.dropdown .menu {
          right: -12px;
          top: 46px;
          left: inherit;
      }
    }
  }

  .container{
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;

    @include for-tablet-portrait-up {
      width: auto;
      margin-left: 0em !important;
      margin-right: 0em !important;
    }

    @include for-tablet-landscape-up {
        width: 100%;
        max-width: 1127px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
  }

  .main-container {
    padding-top: 70px;
    margin-bottom: 2em;

    .shop, .card-billingform, .card-application, .checkout{
      background-color: white;
      box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.16)
    }
  }
}

.view-header{
  text-align: left;

  h1.header{
    margin-bottom: 0.1em;
    padding-top: 0.5em;
  }

  &:before{
    content: "";
    margin-right: 16px;
    float: left;
    background-repeat: no-repeat;
  }

  a{
    display: block;
    font-weight: 800;

  }
}

@include tablet{
  .no-mobile {
    display: none !important;
  }
}

.cardfooter{
  text-align: center;
  color: $grey;
  width: 100%;
  padding: $distance-above-3 5% 0 5%;
  border-top: 1px solid $grey-light;
  margin-top: $distance-above-2;
}

.generic-message{
  width: 100% !important;
  height: 480px !important;

  @include for-tablet-portrait-up{
    width: 480px !important;
  }

  box-sizing: border-box;
  background-color: white;
  margin: 2em auto 0 auto !important;
  padding: 40px 40px !important;
  line-height: 1.5em;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.16);

  &.success, &.error{
    h1:before{
      content: '';
      height: 113px;
      width: 113px;
      display: block;
      margin: 1.25em auto 0.75em auto;
      background: url('../assets/success_icon_big_1x.png') repeat;
    }

    .widget+h1:before{
      content: none;
      height: inherit;
      width: inherit;
      background: inherit;
      margin: inherit;
    }
  }

  &.error{
    font-size: $font-size-above-one;

    h1{
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;

      &:before{
        background: url('..//assets/error_icon_big_1x.png') repeat;
      }
    }

    .greytext{
      margin-bottom: 2rem;
    }
    .backbutton{

    }
  }

  .foodsavedcounter{
    margin-top: 24px;
    margin-bottom: 16px;
  }

  p{
    max-width: 320px;
    margin: 0 auto $distance-above-1 auto;
  }

  .standalone-link{
    display: block;
    margin: $distance-above-1 0;
    font-weight: 700;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }
  }

  .backbutton{
    font-weight: 700;
    margin-left: -14px;

    i{
      margin-right: 6px;
    }

    &:hover i{
      text-decoration: none;
    }
  }

}

* {
  box-sizing: border-box;
}

.ui.modal.notification-box{
  max-width: 450px;
  padding: 30px 40px 30px 70px;
  border-radius: 8px;

  .header, .header:not(.ui){
    display: inline-block;
    font-family: $semplicitaPro;
    font-size: 13px;
    line-height: 1.4;
    font-weight: 700;
    padding: 0 0;
    border-bottom: 0px;
    margin-bottom: $distance-default/2;
    user-select: none;
  }

  > .content{
    padding: 0 0;
    font-family: $semplicitaPro;
  }

  .description, .description h4{
    display: inline-block;
    font-family: $semplicitaPro;
    font-size: 13px;
    line-height: 1.4;
    font-weight: normal;
    user-select: none;
  }

  .description{
    margin-bottom: 16px;
    width: 100%;
  }

  .ui.checkbox label{
     a{
       text-decoration: underline;
       cursor: pointer;

       &:hover{
         text-decoration: none;
       }
     }
  }

  &.brown{
    background-color: $brown-light;
    border: 1px solid $brown-grey;
    color: $brown-grey !important;

    &:before{
      content: "";
      width: 32px;
      height: 32px;
      background-image: url('../assets/success-icon-big.svg');
      background-size: 100% 100%;
      position: absolute;
      top: 32px;
      left: 24px;
    }

    .header, .content{
      color: $brown-grey !important;
      background-color: $brown-light;
    }

    .field{
      &:not(.error){
        .ui.checkbox:not(.checked) {
          label{
            color: $brown-grey !important;

            & a{
              color: $brown-grey !important;
            }

            &:before{
              border-color: $brown-grey;
            }
          }
        }
      }
    }
  }

  /*Styling modal rating box*/
  &.rating{
    border: none;
    max-width: 500px;
    background: none;
    background-color: white;
    white-space: normal;
    text-align: center;
    padding: 30px 40px 40px 40px;

    h1,h2,h3,h4,h5,p,span,label,.label{
      color: $black !important;
    }

    .label{
      font-weight: 700;
      display: block;
    }

    .dropdown .text{
      color: $black !important;
    }

    .header, .content{
      color: $black !important;
      background-color: transparent;
    }

    .header{
      color: $black !important;
      font-size: 1.8em;
      margin: $distance-above-2 0 $distance-above-1 0;

      &.simple-rating-header{
        margin-top: $distance-default;

        &:before{
          content: "";
          height: 104px;
          width: 138px;
          margin: 0 auto;
          display: block;
          background-image: url('../assets/rating_icon.png');
          background-size: 100% 100%;
          transform: scale(.8);

        }
      }
    }

    > .close {
        color: rgba(255, 255, 255, 0.9);
        top: 0.5rem;
        right: 0.5rem;
    }

    &:before{
      content: "";
      width: 32px;
      height: 32px;
      background-image: url('../assets/rating_icon.png');
      background-size: 100% 100%;
      position: absolute;
      top: 28px;
      left: 22px;
    }

    .ui.rating .icon{
      font-family: inherit;
      line-height: inherit;
      content: "";
      height: 20px;
      width: 20px;
      background-image: url('../assets/rating-star-brown.svg');
      background-size: 100% 100%;
      margin-right: 4px;

      &:before, &.active{
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../assets/rating-star-yellow.svg') 100% 100% no-repeat !important;
        opacity: 0;
        transition: all ease 0.4s, transform ease 0.2s;
      }

      &.selected{
        &:before{
          opacity: 1;
        }
      }
    }

    .description{
      color: $grey-dark;
      margin-bottom: 10px;
      max-width: 360px;
      margin: 0 auto 1em;

      .rating{
        margin-bottom: $distance-default;
      }
    }

    .ui.large.rating{
        display: block;
        outline:0;

        .icon{
          opacity: 1;
          background-image: url('../assets/rating-star-grey.svg');
          outline:0;
        }
    }

    label{
      padding: 4px 4px;
      display: block;
      font-weight: 700;
    }

    textarea{
      border-radius: $border-radius-default;
      border: 1px solid $grey;
      box-shadow: inset 0 0 0 0px $green;
      padding: 1rem 0.875rem;
      color: $black;
      height: $input-height*3;
      transition: all 0.3s ease;
      max-width: 500px;
      height: inherit;
      min-height: 100px;
      max-width: 330px;
      width: 100%;

      &:focus {
        border-color: $green;
        box-shadow: inset 0 0 0 2px $green;
        transition: all 0.3s ease;
      }
    }

    .actions{
      max-width: 330px;
      margin: $distance-default auto 32px;
      display: flex;
      flex-direction: column;

      &.simple-rating-actions{
        margin: $distance-default auto 8px;
      }

      .button{
        margin: 0 0;
        display: block;

        &.primary{
          width: 100%;
        }

        &.secondary{
          border: 0px !important;
          margin-top: $distance-above-1 !important;
          font-size: .875em;
        }
      }
    }

    .button2text{
      color: $green;
      font-size: 13px;
    }

    .footer{
      margin: 0 auto;
      padding-top: 0px;
      border-top: 0px solid #ededed;

      span{
        text-align: center;
        color: $grey !important;
        font-size: .875em;
      }
    }

    &.extended-rating{
      > .close {
          color: #DDD9D3;
          top: 0.5rem;
          right: 0.5rem;

          &:before{
            content: "\F00D";
          }
      }

      &:before{
        width: inherit;
        height: inherit;
        background: inherit;
      }

      .feedbacksuccess{
        max-width: 500px;
        padding: 30px 20px 40px 20px;

        h3{
          &:before{
            content: '';
            height: 80px;
            width: 80px;
            display: block;
            margin: 1em auto 1em auto;
            background: url('../assets/success_icon_big_1x.png') repeat;
            background-size: 100% 100%;
            background-position: center center;
          }
        }
      }
    }
  }
}

.button2text{
  margin: 0 0 !important;
  padding: 0 0 !important;
  height: auto !important;
  background: none !important;
  text-decoration: underline !important;

  &:hover{
    text-decoration: none !important;
    box-shadow: none !important;
    transform: none !important;
  }

  &:after{
    display: none;
  }
}

.hide{
  display: none !important;
}

.footer{
  text-align: center;
  color: $grey;
  font-size: 0.875em;
  font-weight: 700;
  width: 100%;
  padding-top: $distance-above-3;
  border-top: 1px solid $grey-light;
}
