.auth-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 100%;
}

.ui.card.card-auth {
  width: 100%;
  max-width: 100%;
  padding: $distance-default * 4 $distance-default;
  border-radius: 0;
  // border: 1px solid $highlight;

  @include for-tablet-portrait-up {
    width: 450px;
    padding: $distance-default * 4 $distance-default * 6;
  }

  @include mobile {
    padding: $distance-above-2;
  }

  h2.ui.header {
    color: $black;
    font-size: 1.25em;
    margin-bottom: $distance-above-1;
  }

  .buttonrow {
    display: flex;
    margin-top: $distance-above-1;

    //reorder buttons for mobileview
    div:first-child {
      order: 1;
    }
    div:nth-child(2) {
      order: 0;
    }

    @include for-tablet-portrait-up {
      display: block;
      margin-top: inherit;
    }
  }

  // .ui.button {
  //   width: auto;
  // }

  &.card-application {
    width: 100%;
    max-width: 100%;

    @include for-tablet-portrait-up {
      width: 90%;
    }

    @include for-tablet-landscape-up {
      width: 800px;
      max-width: 70%;
    }

    @include mobile {
      width: 100%;
      max-width: 100%;
      padding: $distance-above-2 0;
    }

    .ui.checkbox {
      padding-top: $distance-default;
    }

    .cardfooter {
      margin-top: $distance-above-1;
    }
  }

  .delivery-window {
    color: black;
    font-size: $font-size-below-one;
    font-weight: 700;

    .field {
      display: inline;

      .dropdown {
        font-weight: 400;

        > .dropdown.icon,
        > .search.icon {
          padding: 1.15rem 0.6rem;
        }
      }
    }

    div.textwrapper {
      padding: 0 8px;

      &:first-child {
        display: inline-block;
        padding: 0 0 8px 0;
        width: 100%;
      }

      &:last-child {
        padding: 0 0 0 8px;
      }

      span {
        padding: 0 8px;
      }

      span:first-child {
        width: 100%;
        padding: 0 0;
        display: inline-block;
        margin: 0 0 $distance-below-1 0;
      }
    }

    .ui.selection {
      min-width: 30px;
      max-width: 100px;

      &.deliveryWindow {
        max-width: 50px;
      }
    }
  }

  .pickup-window {
    color: $black;
    font-size: 0.875em;
    font-weight: 700;
    padding-bottom: 4px;

    div.timespan-wrapper {
      padding: 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;

      .timespan-container {
        display: flex;
        align-items: baseline;

        span {
          margin: 0 4px;
        }

        input {
          width: 60px;
        }
        .field {
          margin-bottom: 16px;
        }
      }
    }

    .react-timerange-picker__wrapper {
      padding: 0 8px;
      background-color: white;
      border-color: #969696;
      border-radius: $border-radius-default;
      margin-left: 16px;
      margin-bottom: 16px;

      input[type='number'],
      input[type='password'],
      input[type='search'],
      input[type='tel'],
      input[type='text'],
      input[type='time'],
      input[type='url'] {
        font-family: $semplicitaPro;
        margin: inherit;
        outline: inherit;
        line-height: inherit;
        padding: inherit;
        font-size: inherit;
        background: transparent;
        border: inherit;
        color: $black;
        border-radius: inherit;
        box-shadow: inherit;
        transition: inherit;

        &.react-timerange-picker__inputGroup__input--hasLeadingZero {
          margin-left: -11px;
          text-align: right;
        }
      }

      input:not([type]):focus,
      input[type='date']:focus,
      input[type='datetime-local']:focus,
      input[type='email']:focus,
      input[type='file']:focus,
      input[type='number']:focus,
      input[type='password']:focus,
      input[type='search']:focus,
      input[type='tel']:focus,
      input[type='text']:focus,
      input[type='time']:focus,
      input[type='url']:focus {
        background: transparent;
        box-shadow: none;
      }
    }

    .react-timerange-picker,
    .react-timerange-picker *,
    .react-timerange-picker *:before,
    .react-timerange-picker *:after {
      box-sizing: border-box;
      height: 48px;
      line-height: 48px;
      display: inline-flex;
      align-items: center;
    }

    .react-time-picker__inputGroup__divider {
      margin-right: 4px;
    }

    .react-timerange-picker__inputGroup {
      min-width: 50px;
      height: 100%;
      flex-grow: 1;
      padding: 0 2px;
      font-size: 14px;
      box-sizing: content-box;
    }

    .react-timerange-picker__inputGroup__input {
      min-width: 22px;
      text-align: left;
    }

    .hint {
      color: $grey;
      font-size: 0.85em;
      line-height: 1.4em;
      padding: 8px 0;
    }

    .ui.selection {
      min-width: 132px;

      &.small-dropdown {
        min-width: 66px;
      }
    }
  }
}

.PageContainer {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.NavigationBarContainer {
  display: flex;
  width: 100vw;
  height: 90px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.5) 0px -1px 10px;
  z-index: 10;
  justify-content: center;
  align-items: center;

  img {
    height: 42px;
  }
}

.ApplicationBox {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px !important;
  border-radius: 6px;
  position: relative;
  min-width: 620px;

  margin-top: 70px !important;
  padding: 65px 74px 37px !important;

  h3.ui.header {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    margin-bottom: 27px;
  }

  @media only screen and (max-width: 767px) {
    min-width: 100vw;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0px !important;
    padding: 140px 1rem 2rem !important;
  }

  .ui.card &__HeaderIconContainer {
    position: absolute;
    top: -45px;
    width: 91px;
    height: 91px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    background: $green;
    border-radius: 100px !important;

    @media only screen and (max-width: 767px) {
      top: 35px;
    }
  }

  &.ui.card > .content {
    border-top: 0px;
  }

  &.ui.card:last-child {
    margin-bottom: 24px;
  }

  &__footer {
    margin-top: 12px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 19px;

    a {
      color: $green;
    }
  }
}
