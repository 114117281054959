.card-support{
  max-width: 900px !important;
  width: 100% !important;
  padding: 32px 48px;
  border-radius: 0 !important;
  margin: 0 auto !important;

  .content{
    padding: 0 0 !important;

    .faqsection{
      text-align: left;
      padding: 0 36px;

      h1{
        text-align: center;
        margin-bottom: $distance-default;

        &:before{
          content: '';
          height: 104px;
          width: 138px;
          display: block;
          margin: 0.75em auto 0em auto;
          background: url('../assets/faq_icon_1x.png') no-repeat;
        }
      }

      p{
        line-height: 1.8;

        &.intro{
          max-width: 480px;
          font-weight: 600;
          margin: 0 auto $distance-above-2;

          @include for-phone-only{
            br{content:' ';}
            br:after{content:' ';}
          }
        }

        &.outro{
          margin: $distance-above-2 10px $distance-above-3 10px;
          padding-bottom: 20px;
          font-weight: 700;
        }
      }

      .wrapper{
        margin-bottom: $distance-above-2;

        section{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          border-top: 1px solid $grey-light;

          &:last-child{
            border-bottom: 1px solid $grey-light;
          }
        }

        h2{
          font-size: 1.5em;
          line-height: 1.5em;
          padding: 20px 10px;
          margin-top: -.14285714em;
          width: 100%;

          @include for-tablet-only{
            margin-bottom: 0px;
          }

          @include for-tablets-up{
            width: inherit;

            &.section{
              flex-basis: 33.33%;
              flex-grow: 1;
              padding-right: 10%;
            }
          }
        }

        .accordion{
          flex-basis: 66.66%;
          flex-grow: 2;
        }
      }

      .ui.accordion, .ui.accordion .accordion{
        .title{
          font-family: $semplicitaPro;
          font-weight: 700;
          font-size: $font-size-above-three;
          line-height: 1.5em;
          color: $grey;
          user-select: none;
          border-top: 1px solid $grey-light;
          padding: 20px 10px;
          transition: all 0.3s ease;

          &:first-child{
            border-top: 0px;
          }

          &.active, &:hover{
            color: $black;
          }

          .dropdown.icon{
            float: right;
            margin-top: .75%;

            @include for-tablet-only{
              margin-top: 1%;
            }

          }
        }

        .content{
          padding: 0px 40px 30px 10px !important;

        }
      }

      .faqArrow{
        width: 32px;
        position: absolute;
        left: 50%;
        margin: -16px 0 0 -16px;
      }
    }

    .formsection{
      padding: 4em 1em;

      .supportform{
        max-width: 550px !important;
        margin: 0 auto;

        .column > p{
          margin: $distance-above-1 0;

          @include for-phone-only{
            br{content:' ';}
            br:after{content:' ';}
          }
        }

        #field_textarea_request{
          max-width: 100%;
        }

        .formfooter{
          margin-top: $distance-default;

          .disclaimer{
            float: left;
            font-style: italic;

            span{
              color: $red !important;
            }
          }
        }

        .error.field{
          text-align: left;
        }

      }
    }
  }

  .reclamation{
    h1{
      &:before{
        content: '';
        height: 80px;
        width: 90px;
        display: block;
        margin: 0em auto 0.15em auto;
        background: url('../assets/caution_icon_big_1x.png') no-repeat;

      }
    }

    .ui.form.formsection{
      padding: 2.5em 1em 3em 1em;

      .content{
        max-width: 550px;
        margin: 0 auto;

        .header{
          margin-bottom: 8px;
        }

        .intro{
          margin: 0 0 $distance-above-2;
        }

        label+.dropdown{
          margin: 0 0 $distance-above-1;
        }

        .ui.message.neutral{
          display: inherit;
          flex-direction: inherit;
          margin-bottom: 12px;
          -webkit-animation: fadeIn .5s ease forwards;
          animation: fadeIn .5s ease forwards;


          > div{
            padding-left: 32px;
          }

          .header{
            display: inline-block;
            margin-bottom: 0px !important;

            &:before{
              margin-top: 2px;
            }
          }

          .ui.list{
            font-size: .9em;
            margin-top: 12px;

            @include for-phone-only{
              padding: 0px;
            }
          }
        }

        .amount{
          display: flex;
          flex-direction: row;

          .ui.input{
            max-width: inherit;
            display: inline-block;
            width: inherit;
          }

          .field{
            width: 100%;
          }

          .error{
            position: absolute;
            bottom: 2px;
            left: 8px;
          }
        }

        .red.button{
          min-width: inherit;
          display: inline-block;
          margin-left: $distance-default;
        }

        .supportform{
          .column.grid{
            margin-top: 0px;

            .row{
              padding: 0 0;
              -webkit-animation: fadeIn .75s ease forwards;
              animation: fadeIn .75s ease forwards;
            }

            .wide.column{
              &.ten{
                padding-right: 8px;

                .field label{
                  @include for-phone-only{
                    height: 34px;
                  }
                }
              }
              &.six{
                padding-left: 8px;

                @include for-phone-only{
                  padding-left: 0px;
                }

                .field label{
                  @include for-phone-only{
                    height: 34px;
                  }
                }
                input{
                  width: 100%;
                }
              }

              .field label{
                &:after{
                  content: "*";
                  color: $red;
                }
              }
            }
          }

          .add-article{
            width: 100%;
            transition: opacity 0.3s ease-in-out;
            background-color: inherit !important;
            background-color: white;
            border: 0px solid #64b457 !important;
            padding: 0 0 !important;
            height: auto;
            margin-top: 16px;
            text-decoration: underline;

            &:after{
              box-shadow: inherit;
            }

            &:hover{
              transform: scale(1);
              box-shadow: none;
              text-decoration: none;

              &:after{
                box-shadow: inherit;
              }
            }

            &:active{
              transform: scale(1);
            }
          }

          .column.grid+.ui.button{
            margin: $distance-above-1 0;
          }

          .field label::last-letter{
              color: $red !important;
          }

          .upload-wrapper{
            label{
              @include for-phone-only{
                width: 100%;
              }

              text-align: left;
              display: block;
            }
            button{
              margin: 0 0 0 0;
              display: block;
              width: 100%;
            }

            .wide.column{
              padding-top: 0px;

              @include for-phone-only{
                width: 100% !important;
              }

              &.five{
                padding-right: 8px;

                @include for-phone-only{
                  padding-left: 8px;
                }
              }
              &.eleven{
                padding-left: 8px;

                @include for-phone-only{
                  padding-right: 1rem;
                  padding-left: 1rem;
                }
                display: flex;
                flex-direction: column;
              }
            }
          }

          .error{
            font-size: 0.875em;
            font-weight: 700;
            text-align: left;
            margin-top: -12px;
          }

          .file-names{
            display: flex;

            .wrapper{
              display: block;
              padding: 8px 8px;
              background-color: white;
              color: $grey-dark;
              width: 100%;
              font-size: 0.85em;
              letter-spacing: .4px;
              min-height: 48px;
              border-radius: 4px;
              text-align: left;
              transition: all 0.3s ease;
              overflow: hidden;
            }

            .error{
              width: 100%;
              margin-top: 4px;
              text-align: right;
            }
          }
        }
      }

      .field #field_textarea_comment{
        max-width: inherit;
      }
    }
  }
}
