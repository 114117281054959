.ui.sidebar {
  max-width: 100%;
  width: 100%;
  padding-bottom: 24px;

  @include for-tablets-up {
    max-width: 60%;
    min-width: 840px;
    padding-bottom: 0px;
  }

  &.ui.vertical.menu .item:before {
    display: none !important;
  }

  .ui.form {
    .no-cart {
      display: none;
    }
  }

  .cart-header {
    text-align: left;
    padding: 6% 8px 0 8px;

    @include for-tablets-up {
      padding: 24px 24px 0 24px;
    }

    h1.header {
      margin-bottom: 0.1em;
      line-height: 0.6em;
      padding-top: 25px;

      @include for-phone-only {
        margin-top: -4px;
        font-size: calc(30px + 3vw);
      }
    }

    &:before {
      content: '';
      height: 80px;
      width: 100%;
      max-width: 12%;
      min-width: 48px;
      margin-right: 16px;
      margin-top: -2px;
      float: left;
      background-image: url('../assets/basket_icon_big_1x.png');
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;

      @include for-tablet-portrait-up {
        max-width: 72px;
        margin-right: 24px;
        margin-top: -$distance-default;
      }
    }
  }

  .cart {
    text-align: left;

    @include for-tablets-up {
      padding: $distance-default * 3;
    }

    h3.header {
      color: $grey;
      font-size: $font-size-above-one;
      margin-top: -1 * $distance-default/2;
      margin-bottom: -0.14285714em;

      @include for-tablets-up {
        margin-top: -$distance-above-2;
        margin-bottom: $distance-above-2;
      }
    }

    .backtoshop {
      position: absolute;
      top: 18px;
      left: 20px;

      @include for-tablet-portrait-up {
        display: none;
      }
    }

    .cart-label {
      font-weight: 300;
    }

    .ui.table {
      margin-bottom: $distance-above-1;

      @include for-tablets-up {
        margin-bottom: $distance-above-2;
      }

      &:not(.unstackable) tr > td,
      .ui.table:not(.unstackable) tr > th {
        padding: 0.25em 0.5em !important;

        @include for-tablets-up {
          padding: 0.928571em 0.75em !important;
          padding-left: 20px !important;
        }
      }

      tbody {
        &:empty {
          &:before {
            height: 128px;
            content: '';
            display: block;
          }
          &:after {
            content: 'Keine Artikel im Warenkorb';
            font-size: 2em;
            font-family: $semplicitaPro;
            font-weight: 600;
            text-align: center;
            display: block;
            color: $grey;
            padding: 0.75em 6%;
            width: 100%;
            top: 20px;
            line-height: 1.2;
            border-bottom: 1px solid $grey-light;
            border-top: 1px solid $grey-light;
            position: absolute;

            @include for-tablets-up {
              padding: 2em;
              top: 50px;
              border-top: inherit;
            }
          }
        }

        td {
          &.produce-price {
            @media screen and (max-width: 768px) {
              text-align: right;
            }
          }

          &.quantity {
            @media screen and (max-width: 768px) {
              grid-column: 1;
              grid-row: 2;
              text-align: left;

              &:before {
                display: inline-block;
                margin-right: 4px;
              }
            }
            min-width: 86px;
          }

          &.description {
            display: none !important;

            @include for-tablets-up {
              display: block;
            }
          }

          &.spacer {
            border: 0;
            overflow: hidden;
            min-width: 130px;
            padding: 0.8em 0 0 0;
            border-bottom: 0px solid $grey-light !important;
            grid-column: 1;
            grid-row: 3;

            @include for-tablets-up {
              position: absolute;
              margin-left: -26px;
              margin-top: -2px;
              min-width: 140px;
            }

            @media (max-width: 460px) {
              grid-column: 2;
              grid-row: 2;
              left: 0px;
              right: 0px;
              top: 0px;

              .custom-error {
                max-width: 100%;
              }
            }

            .custom-error {
              background-color: $red-light;
              min-height: 50px;
              font-size: 0.75em;
              display: none;
            }
          }

          &.article-total {
            @media screen and (max-width: 768px) {
              grid-column: 2;
              grid-row: 3;
              text-align: right;
              margin-top: 8px;
              margin-right: 50px;
              align-self: center;
            }
          }

          &.delete-article {
            @media screen and (max-width: 768px) {
              grid-column: 2;
              grid-row: 3;

              .button {
                margin-top: 8px;
              }
            }
          }
        }
      }

      tr.has-error {
        .custom-error {
          display: flex !important;
        }
      }
    }

    .ui.table tbody tr:last-child {
      border-bottom: 1px solid $grey;
    }

    .ui.table tr td:last-child {
      padding: 0 0;
      width: 40px;
    }

    .ui.table button.no-shop {
      min-width: inherit;
      width: 36px;
      float: right;
      margin: 0 $distance-below-2 0 $distance-below-2;
    }

    .article-total {
      color: black;
      font-weight: 600;
    }
  }

  button.secondary,
  #submitButton {
    width: 100%;
    margin-top: 16px;

    button.primary {
      width: 100%;

      @include for-tablets-up {
        width: auto;
      }
    }

    @include for-tablets-up {
      width: auto;
    }
  }

  #submitButton {
    float: right;
  }
}

.cart-footer {
  color: $grey;
  display: flex;
  margin-bottom: $distance-default;
  flex-direction: column;
  padding-top: $distance-above-1;

  @include for-tablets-up {
    flex-direction: row;
    margin-bottom: $distance-above-2;
    justify-content: space-between;
  }

  .cart-hints {
    font-size: $font-size-below-two;
    padding: 0 8px 0 8px;
    line-height: 1.4;
    order: 2;

    @include for-tablet-portrait-up {
      width: 70%;
    }

    @include for-tablets-up {
      padding: inherit;
      padding-right: 10%;
      padding-top: 0px;
      order: inherit;
      height: 36px;
      display: flex;
      align-items: center;
    }
  }

  .cart-values {
    flex-grow: 1;
    order: 1;
    color: $black;
    font-weight: 600;

    @include for-tablets-up {
      order: inherit;
    }

    .values-primary {
      padding: 0 $distance-default 0 $distance-default;
    }

    .values-row {
      font-size: $font-size-above-one;
      display: flex;
      justify-content: space-between;
      margin-bottom: $distance-above-1;
    }

    .values-gross-total {
      border-top: 1px solid $black;
      padding: 0 $distance-default 0 $distance-default;

      .values-row {
        margin-top: $distance-above-1;
        font-size: $font-size-above-three;
      }

      .cart-hint {
        font-size: $font-size-below-two;
        line-height: 1.4;
        color: $grey;
        font-weight: 400;
      }
    }
  }
}
