.checkout {
  display: inline-block;
  width: 100%;
  max-width: 900px;
  text-align: left;
  padding-top: $distance-above-3;
  background-color: white;
  padding: 5% 4%;
  margin: 0 auto;

  @include for-tablet-portrait-up {
    padding: $distance-default * 4 $distance-default * 6 0 $distance-default * 6;
  }

  .no-checkout {
    display: none;
  }

  .view-header {
    margin: 1.2em 0 1.8em 0;
    padding-top: $distance-default;

    $billingform-image-height: 76px;

    h1.header {
      margin-bottom: 0em;
      padding: inherit;
      padding-top: $distance-default;
      line-height: $billingform-image-height;

      @include for-phone-only {
        font-size: 10vw;
        line-height: 1.2;
      }
    }

    &:before {
      content: '';
      display: inline-block;
      height: 49px;
      width: 37px;
      background-image: url('../assets/checklist_icon_big_1x.png');
      margin-top: -4px;
      background-position: center center;
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-right: 8px;

      @include for-tablet-portrait-up {
        max-width: 81px;
        margin-right: 24px;
        height: $billingform-image-height;
        width: 57px;
        margin-top: -$distance-default;
      }
    }
  }

  h3.ui.header:not(.accordion) {
    padding-left: 2px;
  }

  .summary-intro {
    max-width: 600px;
    padding-left: 2px;
  }

  .ui.form .field .ui.input input,
  .filters .field .ui.input input,
  .ui.form .field textarea,
  .filters .field textarea {
    max-width: 100%;

    @include for-tablet-portrait-up {
      max-width: 500px;
    }
  }

  .customer-information {
    .ui.form {
      textarea {
        height: 100px;
      }

      @include for-tablet-landscape-up {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: baseline;
        max-width: 616px;

        input,
        textarea {
          min-width: 294px;
          height: 48px;
        }

        .field {
          margin-bottom: $distance-default;
        }
      }
    }
  }

  .ui.form {
    &.accordion {
      margin-top: -1em;

      > .label {
        font-size: 0.92857143em;
        margin: 0 0 0.28571429rem 0;
      }

      .accordion {
        width: 380px;
      }

      & + .ui.form {
        min-width: 294px;
      }
    }

    .summary-intro {
      max-width: 620px;
      font-size: 12px;
      padding-bottom: $distance-default;
    }

    .delivery-address,
    .invoice-settings {
      .field {
        display: inline-block;
        margin-right: $distance-above-2;

        &.label {
          display: block;
        }
      }

      .newaddress {
        display: block;
        margin-top: -12px;
      }
    }

    .invoice-settings {
      margin-top: $distance-above-1;
    }

    .address {
      margin-bottom: $distance-default;
    }

    .placeholder-link {
      color: $highlight;
      cursor: default;
    }

    .ui.table {
      margin-top: $distance-above-2;
      margin-bottom: $distance-above-2;
    }

    .delivery-window {
      margin-top: $distance-above-1;

      @media screen and (max-width: 360px) {
        .ui.selection.dropdown {
          margin-bottom: 8px;
        }

        .hint.information {
          br {
            display: none;
          }
        }
      }
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .accordion.ui {
      padding: 1px 0 $distance-above-1;
      margin-top: 0;

      .title {
        cursor: pointer;
        background-color: #fff;
        border: 2px solid #64b457;
        color: #64b457 !important;
        /* width: auto; */
        display: inline-block;
        font-family: 'semplicitapro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-size: 1em;
        border-radius: 4px;
        padding: 0.9rem 1.45rem 0 0.875rem !important;
        margin: 0 8px 0 0;
        min-width: 142px;
        max-width: 600px;
        height: 48px;
        /* line-height: 48px; */
        font-weight: 800 !important;
        -webkit-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        -webkit-transition: background-color ease 0.4s, -webkit-transform ease 0.2s !important;
        transition: background-color ease 0.4s, -webkit-transform ease 0.2s !important;
        transition: background-color ease 0.4s, transform ease 0.2s !important;
        transition: background-color ease 0.4s, transform ease 0.2s, -webkit-transform ease 0.2s !important;
        -webkit-transform: translateZ(0px);
        /* transform: translateZ(0px); */

        @include for-phone-only {
          width: 100%;
        }

        &.active {
          background-color: $green;
          color: white !important;
        }
      }

      .title ~ .content {
        -webkit-transition: opacity 600ms, visibility 600ms;
        transition: opacity 600ms, visibility 600ms;
      }

      .active.content {
        animation: fade 1s;
        padding-top: $distance-above-2;
      }
    }

    .ui.table {
      margin: $distance-above-2 0;
      table-layout: fixed;

      tr:first-child td {
        border-top: 1px solid $grey-light;
      }

      tr:last-child td {
        border-bottom: 1px solid $grey-light;
      }

      th.sum {
        text-align: right;
        width: 140px;
      }

      .article-total {
        text-align: right;
      }
    }
  }

  .noteditable,
  &.noteditable {
    .ui.table {
      .spacer {
        display: none;
      }
      button {
        display: none;
      }

      .order-input {
        border: none;

        @include for-tablet-only {
          height: 24px;

          .label {
            line-height: 24px !important;
          }
        }

        input {
          border: none;
          font-size: 1em;
          font-weight: 800;
          background-color: transparent;
          color: $grey-dark;
          text-align: right;
          padding-right: 0;
          font-family: $semplicitaPro;
        }

        .label {
          font-size: 1em;
          border: none;
          background: transparent;
          padding: 0 0;
          line-height: 48px;
          color: $grey;
        }
      }

      .product-name {
        width: 200px;
      }

      .field .ui.input input:disabled {
        background-color: inherit;
        color: $black;
        font-weight: 700;
        padding-right: 4px;
        text-align: right;
      }

      &:not(.unstackable) tr > td,
      &:not(.unstackable) tr > th {
        padding: 0.25em 0 !important;
      }

      & .produce-price,
      .quantity {
        @include for-tablet-only {
          text-align: right !important;
        }
      }
    }
  }

  .editable {
    .ui.table {
      .table-header th:last-child {
        display: table-cell;
      }

      tr td:last-child,
      tr th:last-child {
        padding: 0 0;
        width: 40px;
      }

      tr {
        td {
          &.article-total {
            padding-right: 56px !important;
          }
        }
      }

      .ui.button.no-shop {
        min-width: inherit;
        width: 36px;
        float: right;
        margin: 0 2px 0 2px;
      }

      .delete-article {
        .ui.button.no-shop {
          margin-right: 0px;
        }
      }

      .table-header .spacer {
        display: table-cell;
        width: 30px;
      }

      .has-error {
        tr {
          border-color: #ee442e !important;
          background-color: #feece9 !important;
          border-top: 1px solid #ee442e !important;
          border-bottom: 1px solid #ee442e !important;
        }

        .spacer {
          position: absolute;
          padding-right: 0;
          padding-left: 0;
          width: 140px;
          right: 320px;
          margin-top: 3px;

          span {
            background-color: #feece9;
          }
        }
      }
    }

    .ui.form {
      position: relative;

      .ui.primary.button {
        position: absolute;
        bottom: -88px;
        right: 0px;
      }
      .ui.secondary.button {
        position: absolute;
        bottom: -88px;
        right: 206px;
      }

      @include for-phone-only {
        .ui.secondary.button,
        .ui.primary.button {
          position: inherit;
          display: block;
          width: 100%;
          margin-top: $distance-above-1;
          bottom: inherit;
          right: inherit;

          &:first-child {
            margin-top: $distance-above-1;
          }
        }
      }

      .ui.table {
        th.sum {
          text-align: right;
          width: 100px;
        }
        th.amount {
          text-align: center;
          width: 185px;
        }
      }
    }

    .ui.red.button {
      margin-bottom: $distance-above-2;
      margin-top: $distance-above-1;

      @include for-phone-only {
        display: block;
        width: 100%;
        margin-top: $distance-above-1;
      }
    }
  }

  .checkout-buttons {
    margin-left: -48px;
    width: calc(100% + 96px);
    min-height: 74px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
    padding: 0px 1em 0px 0px;
    z-index: 950;

    .detail-items {
      padding-left: 12px;
    }

    span {
      display: flex;
      justify-content: left;
      align-items: center;
      flex-direction: row;

      .article-count {
        display: none;

        @media (min-width: 720px) {
          display: flex;
          margin-bottom: 4px;
        }

        span {
          margin-left: 4px;
        }
      }

      .cart-total {
        display: none;

        @media (min-width: 640px) {
          display: flex;
          font-size: 1.125em;
          font-weight: 700;
          padding-right: 8px;
        }

        @media (max-width: 720px) {
          flex-direction: column;
          line-height: 1.3em;
        }

        span {
          margin-right: 4px;
        }
      }
    }

    .secondary {
      margin-right: $distance-above-2;
    }

    .cart-icon {
      display: none;
      cursor: default;

      @include for-tablets-up {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 74px;
        min-width: inherit;
        height: 74px;
        background-color: darken($black, 10%);
        flex-direction: column;
        color: white;
        font-size: $font-size-below-two;
        line-height: 1.4em;
        font-weight: 800;
        border-radius: 0px;

        &:hover,
        &:active,
        &:focus {
          background-color: darken($black, 15%);
          color: white;
          transform: scale(1);
        }

        img {
          width: 30px;
          margin-left: -3px;
        }

        span {
          margin-top: -5px;
          margin-bottom: 0px !important;
          user-select: none;
        }
      }
    }
  }
}

/* Sticky checkout bar */
.checkout-view {
  .App-container .ui.segment.pushable {
    overflow: inherit;

    .pusher {
      overflow: inherit;
    }
  }

  .checkout-buttons {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;

    @include for-phone-only {
      position: fixed;
      justify-content: space-between;
      padding: 16px 0;
      left: 0px;
      right: 0px;
      margin: 0 0;

      .left {
        display: none;
      }

      .right {
        width: calc(100% - 96px);
        padding: 0 4%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 440px) {
          button {
            &.primary {
              margin-left: -15px;
              width: 99000%; // don't know how else to do this loooool
            }
          }
        }

        a {
          width: 40%;
          margin: 0;

          @media (max-width: 440px) {
            width: 450px;
            margin: 0 auto;
            padding: 0 10px;
            text-align: center;
          }

          button {
            width: 110%;

            @media (max-width: 440px) {
              &.secondary {
                width: 0px;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.ui.card.card-auth {
  &.card-billingform {
    width: 100%;
    max-width: 900px;
    padding-top: 0;
    margin: 0 auto;
    border-top: none;
    background-color: white;

    .view-header {
      height: 100px;
      margin: 1.2em 0 1.8em 0;

      $billingform-image-height: 76px;

      h1.header {
        margin-bottom: 0em;
        padding: inherit;
        padding-top: $distance-default;
        line-height: $billingform-image-height;
      }

      &:before {
        height: $billingform-image-height;
        width: 57px;
        background-image: url('../assets/checklist_icon_big_1x.png');
      }
    }

    form {
      text-align: left;
    }
  }
}

/* Modal for changed order */
.confirm-changed-order {
  .ui.form .ui.table {
    margin-bottom: $distance-above-2;

    .spacer {
      display: none;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1em 0;
  }
}

.foodsavedcounter.widget {
  display: inline-block;

  .content {
    position: relative;
    display: table-caption;
    padding-right: 48px;
    padding-left: 24px;
    transform: rotate(-4deg);

    .potato {
      height: 106px;
      position: absolute;
      left: 24px;
      top: 2px;
      z-index: 200;
      transform: rotate(-2deg);
    }

    .text-wrapper {
      text-align: left;
      margin-left: 50px;
      user-select: none;
      position: relative;
      z-index: 100;

      .widget-intro,
      .widget-outro {
        display: inline-block;
        padding: 1px 4px 4px 44px;
        font-size: 0.95em;
      }

      .widget-amount {
        display: inline-block;
        width: auto;
        min-width: 230px;
        color: white;
        background-image: url('../assets/type-wood-texture@2x.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 6px 18px 10px 40px;
        font-weight: 700;
        font-size: 2.4em;
        line-height: 42px;
        text-align: center;
        white-space: nowrap;
      }
    }

    .successIcon {
      height: 72px;
      width: 72px;
      position: absolute;
      bottom: -25px;
      right: 0px;
      z-index: 50;
      transform: rotate(9deg);
    }

    .splashes {
      position: absolute;
      height: 46px;

      &.top-right {
        right: 6px;
        top: -12px;
      }

      &.bottom-left {
        transform: scaleX(-1) rotate(48deg);
        left: 0px;
        bottom: 2px;
      }
    }
  }
}
