.ui.form, .ui.orderhistory {
  .ui.table {
    line-height: 1.5em;
    border-collapse: collapse;

    th,td{
      padding: 0.928571em .75em;

      @media (min-width: 768px) and (max-width: 820px){
        padding: 0.928571em .25em;
      }
    }
    .table-header {
      color: $brown-grey;
      background-color: $brown-light;
      border-top: 1px solid $grey-light;
      border-bottom: 1px solid $brown-grey;
      font-weight: 500;
      display: none;

      @include for-tablets-up{
        display: table-row;
      }

      th{
        color: inherit;
        background-color: inherit;
        border: inherit;
        font-weight: inherit;
      }
    }

    tr{
      border-top: 1px solid $grey-light;

    }

    tbody tr:last-child {
      border-bottom: 1px solid $grey-light;
    }

    td {
      color: $grey;
      border-top: none;
    }
  }
}

.ui.orderhistory{
  .ui.table {
    .table-header {
      @include for-tablets-up{
        display: table-header-group;
      }
    }
  }
}
