
.ui.form, .filters {

  h1.ui.header{
    a{
      display: block;
      font-size: 12px;
      line-height: 2;
    }
  }

  h3.ui.header {
    margin-bottom: $distance-above-1;

    &:not(:first-child){
      margin-top: $distance-default;
    }
  }

  div.field+h3.ui.header{
    margin-top: $distance-above-3;
  }

  .field, .filter-item {
    margin: 0 0 $distance-above-1;

    &.is-optional {
      label:after {
        content: ' (optional)';
        font-weight: 200;
        color: $grey;
      }
    }

    &:last-child:first-child{
          margin: 0 0 $distance-above-1;
    }

    &::selection {
      background: transparent;
    }

    &.has-hint {
      margin-bottom: 0.3rem;
    }

    &.field input:-webkit-autofill{
      box-shadow: 0 0 0 0 ivory inset !important;
      border-color: #e5dfa1 !important;
      transition: all 0s ease !important;
    }

    &.error {
      .ui.input input,
      .ui.selection.dropdown {
        border-color: $error !important;
        background-color: $error-light;

        &:focus{
          box-shadow: inset 0 0 0 2px $error;
        }

        .default.text,
        &::placeholder {
          color: $error;
        }
      }

      label,
      .sui-error-message {
        color: $error;
      }

      .sui-error-message {
        font-size: $font-size-below-two;
      }
    }

    &.small-input, &.big-input{
      .ui.input input{
        max-width: 100%;
      }
    }

    .ui.input {
      &.disabled {
        opacity: 1;
      }

      input {
        border-radius: $border-radius-default;
        border: 1px solid $grey;
        box-shadow: inset 0 0 0 0px $green;
        padding: 1rem 0.875rem;
        color: $black;
        height: $input-height;
        transition: all 0.3s ease;
        max-width: 500px;

        &:focus {
          border-color: $green;
          box-shadow: inset 0 0 0 2px $green;
          transition: all 0.3s ease;
        }

        &::placeholder {
          color: $grey;
        }

        &:disabled {
          opacity: 1;
          border-color: $grey;
          background-color: $grey-light;
          color: $grey;
        }
      }
    }

    textarea{
      border-radius: $border-radius-default;
      border: 1px solid $grey;
      box-shadow: inset 0 0 0 0px $green;
      padding: 1rem 0.875rem;
      color: $black;
      height: $input-height*3;
      transition: all 0.3s ease;
      max-width: 500px;

      &:focus {
        border-color: $green;
        box-shadow: inset 0 0 0 2px $green;
        transition: all 0.3s ease;
      }
    }

    .ui.selection.dropdown {
      border-radius: $border-radius-default;
      border: 1px solid $grey;
      padding: 1.05rem 0.875rem;
      color: $black;
      height: 48px;
      user-select: none;

      &.disabled {
        opacity: 1;
        border-color: $grey;
        background-color: $grey-light;
        box-shadow: inset 0 0 0 0px $grey;

        .text {
          color: $grey;
        }

        .dropdown.icon {
          display: none;
        }
      }

      &.active{
        border: 1px solid $green;
        box-shadow: inset 0 0 0 1px $green;
        z-index: 900;

        .menu{
          border: 2px solid $green;
          background-color: $green-light;

          > .item{
            border-top: 1px solid $green-light;

            &:hover{
              background-color: #E5F1E3;
            }
            &:first-child{
              border-top: 0px;
            }
          }

          .selected{
            background-color: #DBECD9;
          }
        }
      }

      .default.text {
        color: $grey;
      }

      > .dropdown.icon,
      > .search.icon {
        padding: 1.15rem;
      }
    }

    .ui.checkbox {
      input {
        width: 24px;
        height: 24px;
        top: 2px;

        &:before {
          border-color: $grey;
        }
        &:checked ~ label {
          &:after {
            color: #fff;
            width: 24px;
            height: 22px;
            top: 6px;
            left: 0px;
            background-color: $highlight;
            // border-radius: 4px;
          }

          &:before {
            background-color: $green;
            border-color: $green;
          }
        }

        &:focus ~ label {
          color: $black;
        }

      }

      label {
        padding-left: 40px;
        text-transform: none;
      }

      label:before {
        width: 24px;
        height: 24px;
        border: 1px solid $black;
        margin-top: 2px;
      }
    }

    .ui.checkbox.radio{
      input{
        label {
          padding-top: $distance-below-2;
          color: $grey-dark;
          font-size: .8em;
        }

        &:checked ~ label{
          &:before{
            background-color: white;
            border-width: 3px;
          }
          &:after{
            height: 22px;
            width: 22px;
            top: 4px;
            left: 1px;
            background-color: $green;
          }
        }
      }
    }
  }

  .hint {
    font-size: $font-size-below-two;
    line-height: $font-size-above-two;
    padding: $distance-default 0;

    &.information{
      color: $brown-grey;

      &:before{
        content: '';
        height: 12px;
        width: 12px;
        display: block;
        margin: 0 $distance-default 0em 0;
        float:left;
        background: url('../assets/information-icon-brown.svg') no-repeat;
      }
    }
  }


  a.hint{
    color: $green;
  }

  .delivery-window {
    color: black;
    font-size: $font-size-below-one;
    font-weight: 700;

    .field {
      display: inline;

      .dropdown{
        font-weight: 400;

        > .dropdown.icon,
        > .search.icon {
          padding: 1.15rem 0.6rem;
        }
      }
    }

    div.textwrapper{
      padding: 0 8px;

      &:first-child{
        display: inline-block;
        padding: 0 0 8px 0;
        width: 100%;
      }

      &:last-child{
        padding: 0 0 0 8px;
      }

      span{
        padding: 0 8px;
      }

      span:first-child{
        width: 100%;
        padding: 0 0;
        display: inline-block;
        margin: 0 0 $distance-below-1 0;
      }
    }

    .ui.selection {
      min-width: 30px;
      max-width: 110px;

      &.deliveryWindow {
        max-width: 60px;
      }
    }
  }


  .formfooter{
    width: 100%;
    padding: 1em 0;
    text-align: right;
  }
}

.ui.form.label, .label {
  color: $black;
  font-size: $font-size-below-one;
  font-weight: 700;
  letter-spacing: .1px;
  margin: 0 0 $distance-below-1 0;
  text-align: left;

  span {
    color: $red !important;
  }

  .address{
    font-weight: 400;
  }
}

.filters .search{
  .ui.input{
    input{
      max-width: 100%;
    }
  }
}
