$distance-default: 8px;
$distance-below-1: $distance-default / 2;
$distance-below-2: $distance-default / 4;
$distance-above-1: $distance-default * 2;
$distance-above-2: $distance-default * 3;
$distance-above-3: $distance-default * 4;

// COLORS
$black: #333333;
$grey: #969696;
$grey-light: #ededed;
$brown-grey: #968C78;
$brown-light: #f8f7f3;
$brown: #968C78;
$green: #64b457;
$green-light: #EFF7ED;
$green-dark: #52A247;
$yellow: #FFC400;
$blue: #44A2F2;
$blue-light: #F1F9FF;
$blue-border: #BCE0FD;
$red: #ee442e;
$red-light: #FEECE9;

$grey-dark: $black;

//old variables:
$highlight: $green;
$highlight-light: lighten($highlight, 20%);
$light: $grey-light;
$error: $red;
$error-light: $red-light;
$error-light-2: $red-light;
$success: $green;
$success-light: $green-light;

// BORDERS
$border-radius-default: 4px;

/*
## Fonts:
*/
$semplicitaPro: 'semplicitapro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$debbie: 'debbyscript', sans-serif;

$font-size-below-three: 0.625em;
$font-size-below-two: 0.75em; //12px
$font-size-below-one: 0.875em;
$font-size-default: 1em; //16px
$font-size-above-one: 1.125em;
$font-size-above-two: 1.25em;
$font-size-above-three: 1.5em;
// $font-size-default = 1.875rem
// $font-size-default = 2rem

//Heights:
$input-height: 48px;

// BREAKPOINTS
$mobile: 480px;
$tablet: 768px;

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

/*
# Media breakpoints
prepared mixins for breakpoints, named by ranges
*/

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-only {
  @media (max-width: 768px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-portait-only {
  @media (min-width: 600px) and (max-width: 959px) { @content; }
}
@mixin for-tablets-up {
  @media (min-width: 768px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 960px) { @content; }
}
@mixin for-tablet-landscape-only {
  @media (min-width: 960px) and (max-width: 1199px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-desktop-only {
  @media (min-width: 1200px) and (max-width: 1799px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}


//Animation helpers
@-webkit-keyframes fadeIn {
  0% { opacity: 0;  }
  100% { opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0;  }
  100% { opacity: 1; }
}
