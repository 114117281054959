.ui.card.card-auth.card-accountdetails {
  max-width: 100%;
  width: 100% !important;/*need to fix*/
  padding-bottom: 24px;
  margin: 0 auto !important; /*need to fix*/
  border-top: none;
  background-color: white;
  text-align: left;
  padding-top: $distance-above-3;
  padding: $distance-default * 4 $distance-default * 6;

  @include for-tablet-portrait-up{
    width: 90% !important;
  }

  @include for-tablet-landscape-up{
    width: 900px !important;
  }

  @include mobile {
    width: 100% !important;
    max-width: 100%;
    padding: $distance-above-2 0;
  }

  .content{
    padding: 1em 1em !important;
    
    .message{
      .content{
        padding: 0 0 !important; /*need to fix*/
      }
    }

    h1.header{
      @include for-phone-only{

        font-size: 14.5vw;
        line-height: 1.2;

        @media screen and (min-width: 380px) {
          font-size: 11.5vw;
        }
      }
    }

    .ui[class*="two column"].grid{
      padding: 1rem 1rem;

      > .column:not(.row){
        width: 100%;

        @include for-phone-only{
          padding: 0 0;

          &:nth-child(2){
            padding-top: 32px;
          }
        }

        @include for-tablets-up{
          width: 50%;
        }
      }
    }

    .buttonrow{
      display: flex;
      margin-top: $distance-above-1;


      @include for-phone-only{
        flex-direction: column;
        margin-top: 0px;

        .button{
          margin-top: 16px;
        }

        //reorder buttons for mobileview
        button:first-child{
          order: 1;
          width: 100%;
        }
        button:nth-child(2){
          order: 0;
          width: 100%;
          margin-top: 0px;
        }
      }

      @include for-tablet-portrait-up{
        flex-direction: row;
        display: flex;
        margin-top: 0px;

        button{
          width: 50%;
        }
      }

      @include for-tablets-up{
        display: block;
        margin-top: inherit;

        button{
          width: auto !important;
        }
      }
    }
  }

  .view-header{
    margin: 0 0 1.8em 0;

    @include for-tablet-portrait-up{
      padding: 0 1rem;
    }

    $settings-image-height: 71px;

    h1.header{
      margin-bottom: 0em;
      padding: inherit;
      line-height: $settings-image-height;

      @include for-tablet-portrait-up{
        padding-top: $distance-default;
      }
    }

    &:before{
      content: "";
      height: 60px;
      width: 100%;
      max-width: 12%;
      min-width: 48px;
      margin-top: -4px;
      margin-right: 16px;
      float: left;
      display: inline-block;
      background-image: url('../assets/settings_icon_big_1x.png');
      background-position: center center;
      background-size: auto 100%;
      background-repeat: no-repeat;

      @include for-tablet-portrait-up{
        height: 71px;
        max-width: 52px;
        margin-right: 24px;
        width: 52px;
        margin-top: 2px;
      }
    }
  }

  .delivery-window{
    margin-top: $distance-above-1;
  }
}
