.ui.button, .ui.basic.button {
  font-family: $semplicitaPro;
  font-size: $font-size-default;
  border-radius: $border-radius-default;
  padding: 1rem 0.875rem;
  margin: 0 $distance-default 0 0;
  min-width: 142px;
  height: $input-height;
  font-weight: 800 !important; /*need to be fixed*/
  user-select: none !important; /*need to be fixed*/
  transition: background-color ease 0.4s, transform ease 0.2s !important; /*need to be fixed*/
  transform: translateZ(0px);

  &:after {
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }


  &:hover{
    transform: scale(1.05);

    &:after {
      opacity: 1;
    }
  }

  &:active{
    transform: scale(.97);
    background-color: darken( $highlight, 8% );


    &:after {
      opacity: 0.3;
    }
  }

  &:last-child{
    margin: 0 0;
  }

  &.primary {
    background-color: $highlight;
    border: 2px solid $highlight;

    &:hover, &:focus
    {
      background-color: $highlight;
    }

    &:active{
      background-color: darken( $highlight, 8% );
    }
  }

  &.basic{
    background-color: $brown-grey !important; /*need to be fixed*/
    color: white !important; /*need to be fixed*/
    animation: bounce 0.3s ease-in-out alternate;

    &:active{
      background-color: darken( $brown-grey, 8% ) !important; /*need to be fixed*/
    }
  }

  &.secondary, &.secondary.basic {
    background-color: #FFF !important;
    border: 2px solid $highlight !important;
    color: $highlight !important;
    box-shadow: inherit !important;

    &:hover, &:focus
    {
      background-color: #fff;
      color: $highlight !important;
    }

    &:active{
      background-color: darken( #fff, 2% );
    }
  }

  &.red{
    background-color: #FFF;
    border: 2px solid $red;
    color: $red;
    box-shadow: inherit;

    &:hover, &:focus
    {
      background-color: $red;
      color: #FFF;
    }

    &:active{
      background-color: darken( $red, 8% );
    }
  }

  @keyframes bounce
{
 0%{transform: scale(1);}
 50%{transform: scale(1.2);}
 100%{transform: scale(1);}
}

  &.two-line-btn{
    padding: 0 .875rem;
    line-height: 16px;
  }

  .notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
}

.buttonrow{
  padding-left: 1rem;
  padding-right: 1rem;

  @include for-tablet-portrait-up{
    padding: inherit;
  }

  button, .ui.button{
    width: 100%;

    @include for-tablet-portrait-up{
      width: auto;
    }
  }
}
