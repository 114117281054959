body{
  font-family: $semplicitaPro;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  font-size: 12px;
}

.ui.header, .headline{
  font-family: $semplicitaPro;
  font-style: normal;
  font-weight: 700;

  &--is-handwritten{
    font-family: $debbie;
    font-weight: normal;
    font-style: normal;

    color: black;

    /*Background texture wood:*/
    background-color: black;
    background:url('../assets/type-wood-texture@2x.jpg');
    background-size: 90%;
    background-repeat:repeat;
    background-position:0 0;

    -webkit-font-smoothing:antialiased;
    -webkit-background-clip:text;
    -moz-background-clip:text;
    background-clip:text;
    -webkit-text-fill-color:transparent;
    padding: 16px 0 4px 0;
  }
}

.dark-bg{
  .ui.header, .headline{
    &--is-handwritten{
      background-color: white;
      background:url('../assets/type-chalk-texture@2x.jpg');
      background-size: 90%;
      background-repeat:repeat;
      background-position:0 0;

      -webkit-font-smoothing:antialiased;
      -webkit-background-clip:text;
      -moz-background-clip:text;
      background-clip:text;
      -webkit-text-fill-color:transparent;
      padding: 16px 0 4px 0;
    }
  }

}

h1.ui.header--is-handwritten{
  font-size: 4.4em;
}

a {
  color: $green;
  text-decoration: none;

  &:hover{
    cursor: pointer;
    color: $green;
    text-decoration:underline;
  }
}

.centertext{
  text-align: center;
}

.smalltext {
  font-size: 0.875em;
  margin-bottom: $distance-default !important;
}

.greytext {
  color: $grey;
}

.boldtext {
  font-weight: 600;
}

.italictext {
  font-style: italic;
}

.disclaimer {
  text-align: left;
}

.error {
  color: $red;
}
